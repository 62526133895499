$primary: #D8262E;

@import "~bootstrap/scss/bootstrap.scss";
@import "metisMenu.css";
@import "slicknav.min.css";
@import "bootstrap-editable.css";
@import "font-awesome.min.css";
@import "default-css.css";

:root {
  --primary-color: #D8262E;
}

@import "styles.css";
@import "responsive.css";

.metismenu li a i {
  color: $primary;
}

.btn-secondary a {
  color: #ffffff;
}

.breadcrumbs li a, .breadcrumbs li span {
  color: $primary;
}

.user-profile {
  background: $primary;
}

.header-area {
  padding: 0 30px;
}

.dt-bootstrap {
  display: block;
}

.main-content-inner {
  padding: 15px 15px 50px;
}

.start_actions {
  text-align: right;
}

.small-form {
  max-width: 600px;
  margin: 0 auto;
}

//form {
//  border: 1px solid #FF4F87;
//  padding: 20px;
//  border-radius: 20px;
//  background-color: #eaeaea;
//}

label.error {
  font-size: 11px;
  color: #FF0000;
}

.login-bg {
  background: none;
}

.login-form-head {
  background-color: #ffffff;
  max-width: 300px;
  margin: 0 auto;
}

.form-gp.focused label {
  color: $primary;
}

table.dataTable {
  border-collapse: collapse;
  margin-top: 30px;
}

table.dataTable .fa {
  color: $primary;
  font-size: 22px;
}

.start_actions .fa {
  color: #ffffff !important;
  font-size: 16px !important;
}

.card-img-padding {
  padding: 10px;
}

.page-title {
  margin-bottom: 5px;
}

ul.breadcrumbs {
  margin-bottom: 0;
}

.dataTables_length {
  display: inline-block;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff;
  background-color: #ffffff;
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  margin: 5px;
  padding: 5px 10px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #fff;
  background-color: #ffffff;
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  margin: 5px;
  padding: 5px 10px;
}

.dataTables_filter {
  float: right;
}

.dataTables_wrapper {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 30px;
}

.metismenu li {
  list-style:none;
}

.fa:hover{
  text-decoration: none;
}

.login-form-body{
  padding: 0 50px;
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: #eaeaea;
  }

  // Box hover
  &:hover + label:before {
    background: $primary;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }


  // Box checked
  &:checked + label:before {
    background: $primary;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.form-check{
  padding-left: 0;
}

.badge-success {
  color: #fff;
  background-color: rgba(0,125,0,0.5);
}